import { IonButton, IonContent, useIonRouter } from "@ionic/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BarcodeFormat,
  BarcodeValueType,
} from "@capacitor-mlkit/barcode-scanning";
import { some, startsWith } from "lodash-es";
import { Capacitor } from "@capacitor/core";

import AppLayout from "../layouts/AppLayout";
import FloatingBackButton from "../components/buttons/FloatingBackButton";
import useBarcodeScanner from "../hooks/useBarcodeScanner";
import useAppState from "../hooks/useAppState";
import useIonVisible from "../hooks/useIonVisible";
import { UNIVERSAL_LINKS_DOMAINS } from "../constants";
import axios from "../config/axios.config";

const BarcodeScannerPage: React.FC = () => {
  const {
    isPermissionGranted,
    checkPermission,
    startScan,
    stopScan,
    openAppSettings,
  } = useBarcodeScanner();
  const { isAppActive } = useAppState();
  const { isVisible } = useIonVisible();
  const { t } = useTranslation();
  const router = useIonRouter();

  const [isWrongScanningResult, setIsWrongScanningResult] =
    useState<boolean>(false);

  const getSlugAndNavigate = (url: string) => {
    const slug = url?.split(/guidable\.(com|de)/g)?.pop();

    if (slug) {
      router.push(slug);
    } else {
      // If no match, do nothing - let regular routing logic take over
    }
  };

  useEffect(
    () => {
      const scan = async () => {
        // wait result of scanning
        const result = await startScan({ formats: [BarcodeFormat.QrCode] });

        // check if the scanning result is a quick link from branch.io
        if (result?.valueType !== BarcodeValueType.Url) {
          setIsWrongScanningResult(true);
          return;
        }

        // check if the scanning result is a quick link from branch.io
        if (!result?.displayValue?.startsWith("https://guidable.app.link")) {
          setIsWrongScanningResult(true);
          return;
        }

        // get redirection url from branch.io
        let branchIoResult;
        try {
          branchIoResult = await axios.get(
            `https://api2.branch.io/v1/url?url=${result.displayValue}&branch_key=${process.env.REACT_APP_BRANCHIO_KEY}`,
            {
              headers: {
                accept: "application/json",
              },
              cache: false,
            }
          );
        } catch (e) {
          setIsWrongScanningResult(true);
          return;
        }

        // check if the redirection url exists
        const redirectionUrl = branchIoResult?.data?.data?.$desktop_url;
        if (!redirectionUrl) {
          setIsWrongScanningResult(true);
          return;
        }

        // check if the redirection URL is valid and navigate
        if (
          some(UNIVERSAL_LINKS_DOMAINS, (domain) =>
            startsWith(redirectionUrl, domain)
          )
        ) {
          getSlugAndNavigate(redirectionUrl);
        } else {
          setIsWrongScanningResult(true);
          return;
        }
      };

      if (Capacitor.isNativePlatform() && isPermissionGranted && isVisible) {
        scan();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isPermissionGranted, isVisible]
  );

  useEffect(
    () => {
      if (Capacitor.isNativePlatform()) {
        if (isAppActive && isVisible) {
          checkPermission();
        } else {
          stopScan();
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAppActive, isVisible]
  );

  return (
    <AppLayout>
      <IonContent
        style={{
          "--padding-top": "var(--safe-area-inset-top)",
        }}
      >
        <FloatingBackButton style={{ left: "6px" }} />

        {Capacitor.isNativePlatform() && !isPermissionGranted && (
          <div className="relative mx-auto flex min-h-full max-w-xl flex-col items-center justify-center p-5">
            <p className="mb-5 text-center">{t("barcodeScannerPage.title")}</p>
            <IonButton onClick={openAppSettings}>
              {t("barcodeScannerPage.buttons.openSettings")}
            </IonButton>
          </div>
        )}

        {Capacitor.isNativePlatform() && isWrongScanningResult && (
          <div className="relative mx-auto flex min-h-full max-w-xl flex-col items-center justify-center p-5">
            <p className="mb-5 text-center">
              {t("barcodeScannerPage.unexpectedResult")}
            </p>
          </div>
        )}
      </IonContent>
    </AppLayout>
  );
};

export default BarcodeScannerPage;
