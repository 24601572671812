import {
  IonButton,
  IonContent,
  IonIcon,
  useIonRouter,
  useIonViewDidEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { useEffect, useState } from "react";
import { omit, pick } from "lodash-es";
import { closeOutline, chevronBackOutline } from "ionicons/icons";
import clsx from "clsx";

import AppLayout from "../layouts/AppLayout";
import useError from "../hooks/useError";
import useAuthStore from "../stores/useAuthStore";
import useExperienceLevels from "../hooks/useExperienceLevels";
import { Theme, ExperienceLevel } from "../interfaces/Interfaces";
import { useLocale } from "../contexts/LocaleContext";
import {
  CreateCityInput,
  CreateTourByGptInput,
  useCreateTourByGptMutation,
} from "../graphql/backend/__generated__/backend-graphql-sdk.generated";
import useRoutes from "../hooks/useRoutes";
import useToast from "../hooks/useToast";
import { useCity } from "../contexts/CityContext";
import { useTourCreationRequest } from "../contexts/TourCreationRequestContext";
import useIonVisible from "../hooks/useIonVisible";
import { MixpanelEvents, useMixpanel } from "../contexts/MixpanelContext";
import starsIcon from "../assets/tour-creation/stars.svg";
import AppButton from "../components/buttons/AppButton";
import useThemes from "../hooks/useThemes";
import DurationStep from "./CreateTourPage/DurationStep";
import ThemeStep from "./CreateTourPage/ThemeStep";
import ExperienceLevelStep from "./CreateTourPage/ExperienceLevelStep";
import CityStep from "./CreateTourPage/CityStep";
import FloatingBackButton from "../components/buttons/FloatingBackButton";
import { isPointInPolygon } from "../helpers/turf-helpers";

interface CreateTourByAiForm extends CreateTourByGptInput {
  isStartAndEndPointsSelectionEnabled?: boolean;
  isEndPointSameAsStartPoint?: boolean;
}

const Steps: React.FC<{
  currentStep: number;
  experienceLevelsInQueryLocale: Record<string, ExperienceLevel[]>;
  themesInQueryLocale: Record<string, Theme[]>;
  locale: string;
}> = ({
  currentStep,
  experienceLevelsInQueryLocale,
  themesInQueryLocale,
  locale,
}) => {
  switch (currentStep) {
    case 1:
      return <CityStep />;
    case 2:
      return (
        <ExperienceLevelStep options={experienceLevelsInQueryLocale[locale]} />
      );
    case 3:
      return <ThemeStep options={themesInQueryLocale[locale]} />;
    case 4:
      return <DurationStep />;
    default:
      return null;
  }
};

const CreateTourPage: React.FC = () => {
  const { t } = useTranslation();
  const { handleBackendError } = useError();
  const { isVisible } = useIonVisible();
  const { themesInQueryLocale } = useThemes(isVisible);
  const { experienceLevelsInQueryLocale } = useExperienceLevels(isVisible);
  const { locale } = useLocale();
  const router = useIonRouter();
  const { currentPath, loginPath, localePath } = useRoutes();
  const { presentToast } = useToast();
  const { currentCity } = useCity();
  const { mixpanel, mixpanelEnabled } = useMixpanel();
  const {
    tourCreationRequestStatisticsByUser,
    numberOfAvailableTourCreationRequests,
    setTourCreationRequestStatisticsByUser,
  } = useTourCreationRequest();

  const [createTourByGptMutation] = useCreateTourByGptMutation();

  const methods = useForm<CreateTourByAiForm>();

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  const [currentStep, setCurrentStep] = useState(1);
  const [isRequestingCreation, setIsRequestingCreation] = useState(false);

  // Watch the form values we need to validate
  const formSubscription = methods.watch();

  useIonViewWillLeave(() => {
    // Cleanup form state when leaving the page
    methods.unregister([
      "datoExperienceLevelId",
      "datoThemeId",
      "tourDuration",
    ]);
    setCurrentStep(1);
  });

  useIonViewDidEnter(() => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.VIEW_TOUR_CREATION);
    }
  });

  useEffect(
    () => {
      resetForm();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale, currentCity]
  );

  useEffect(() => {
    const subscription = methods.watch((value, { name }) => {
      switch (true) {
        case (name === "startPoint" || name === "isEndPointSameAsStartPoint") &&
          value.isEndPointSameAsStartPoint:
          methods.setValue("endPoint", value.startPoint as any);
          break;
        case name === "isStartAndEndPointsSelectionEnabled" &&
          !value.isStartAndEndPointsSelectionEnabled:
          methods.setValue("startPoint", null);
          methods.setValue("endPoint", null);
          break;
      }
    });

    return () => subscription.unsubscribe();
  }, [methods]);

  const resetForm = async () => {
    let cityData = {};

    if (currentCity) {
      cityData = currentCity.id
        ? { datoCityId: currentCity.id }
        : {
            cityData: pick(currentCity, [
              "name",
              "location",
              "mapboxId",
            ]) as CreateCityInput,
          };
    }

    await methods.reset({
      ...cityData,
      tourDuration: 30,
      datoExperienceLevelId: "",
      datoThemeId: "",
      isStartAndEndPointsSelectionEnabled: false,
      isEndPointSameAsStartPoint: false,
      startPoint: null,
      endPoint: null,
      polygon: null,
    });
  };

  const createTour = async (formValues: CreateTourByAiForm) => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.CREATE_TOUR, {
        formValues,
        currentCityId: currentCity?.id,
        currentCityName: currentCity?.name,
      });
    }

    if (!isAuthenticated) {
      router.push(loginPath(currentPath()));
      return;
    }

    await handleBackendError(async () => {
      setIsRequestingCreation(true);

      const input = omit(formValues, [
        "isStartAndEndPointsSelectionEnabled",
        "isEndPointSameAsStartPoint",
      ]);

      const { errors } = await createTourByGptMutation({
        variables: { input },
      }).catch((e) => {
        setIsRequestingCreation(false);
        throw e;
      });

      setIsRequestingCreation(false);

      if (errors) return errors;

      resetForm();
      router.push(localePath("user-profile/created-tours"), "forward", "pop", {
        unmount: true,
      });

      setTourCreationRequestStatisticsByUser({
        ...tourCreationRequestStatisticsByUser,
        inProgress: tourCreationRequestStatisticsByUser?.inProgress + 1,
      });

      presentToast(
        "createTour.successMessageForTourCreationRequest",
        "secondary",
        20000,
        "top",
        [
          {
            icon: closeOutline,
            role: "cancel",
          },
        ]
      );
    });
  };

  const isStepValid = () => {
    switch (currentStep) {
      case 1: {
        const hasValidCityData = !!(
          currentCity?.id ||
          (currentCity?.name && currentCity?.location)
        );
        if (!hasValidCityData) return false;

        const { polygon, startPoint, endPoint } = formSubscription;
        if (!polygon || (!startPoint && !endPoint)) return true;

        const isPointsInPolygon = !(
          (startPoint && !isPointInPolygon(startPoint.location, polygon)) ||
          (endPoint && !isPointInPolygon(endPoint.location, polygon))
        );

        if (!isPointsInPolygon) {
          presentToast(
            "createTour.form.setStartAndEndPoints.errors.locationOutsideOfSelectedArea",
            "danger"
          );
        }

        return isPointsInPolygon;
      }
      case 2:
        return !!formSubscription.datoExperienceLevelId;
      case 3:
        return !!formSubscription.datoThemeId;
      case 4:
        return !!formSubscription.tourDuration;
      default:
        return false;
    }
  };

  return (
    <AppLayout>
      <IonContent>
        <div
          className="relative mx-auto flex min-h-full max-w-xl flex-col bg-[#FAFAFA] px-5 pb-[140px]"
          style={{
            marginTop: "var(--safe-area-inset-top)",
          }}
        >
          <div className="relative flex items-center gap-1 py-10 pl-10">
            {currentStep > 1 ? (
              <IonButton
                onClick={() => setCurrentStep(currentStep - 1)}
                fill="clear"
                shape="round"
                className="absolute -left-1 top-1/2 m-0 -translate-y-1/2"
              >
                <IonIcon
                  icon={chevronBackOutline}
                  slot="icon-only"
                  className="h-10 w-10"
                />
              </IonButton>
            ) : (
              <FloatingBackButton
                style={{
                  top: "50%",
                  transform: "translateY(-50%)",
                  left: "-10px",
                }}
              />
            )}
            {[1, 2, 3, 4].map((step) => (
              <div
                key={step}
                className={clsx(
                  "h-1 flex-1 rounded-full",
                  step <= currentStep ? "bg-primary" : "bg-gray-300"
                )}
              />
            ))}
          </div>

          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(createTour)}>
              <Steps
                currentStep={currentStep}
                experienceLevelsInQueryLocale={experienceLevelsInQueryLocale}
                themesInQueryLocale={themesInQueryLocale}
                locale={locale}
              />

              <div className="fixed bottom-0 left-1/2 z-10 w-full max-w-xl -translate-x-1/2 bg-[#FAFAFA] px-5 pt-2">
                <div className="mb-2">
                  {currentStep < 4 ? (
                    <AppButton
                      type="button"
                      shape="round"
                      className="w-full"
                      disabled={!isStepValid()}
                      onClick={() => setCurrentStep(currentStep + 1)}
                    >
                      {t("createTour.form.buttons.continue")}
                    </AppButton>
                  ) : (
                    <AppButton
                      type="submit"
                      shape="round"
                      className="w-full"
                      withGradient
                      disabled={!isStepValid() || isRequestingCreation}
                    >
                      <IonIcon src={starsIcon} className="mr-1" />
                      {t("createTour.form.buttons.create")}
                    </AppButton>
                  )}
                </div>
                {!!numberOfAvailableTourCreationRequests && (
                  <div className="mb-4 flex items-center justify-center text-[0.875rem] text-[#848D96]">
                    <div className="mr-2 h-[6px] w-[6px] rounded-full bg-[#E59953]" />
                    <span
                      className="text-[#535E69]"
                      dangerouslySetInnerHTML={{
                        __html: t("createTour.statistics.message", {
                          limit: numberOfAvailableTourCreationRequests?.limit,
                          remainder:
                            numberOfAvailableTourCreationRequests?.remainder,
                        })!,
                      }}
                    />
                  </div>
                )}
              </div>
            </form>
          </FormProvider>
        </div>
      </IonContent>
    </AppLayout>
  );
};

export default CreateTourPage;
