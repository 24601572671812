import {
  Position,
  lineString as turfLineString,
  point as turfPoint,
  Units as TurfUnits,
  polygon as turfPolygon,
} from "@turf/helpers";
import { length as turfLength } from "@turf/length";
import turfDistance from "@turf/distance";
import { bearing as turfBearing } from "@turf/bearing";
import turfBbox from "@turf/bbox";
import { min, max, head, last } from "lodash-es";
import turfBooleanPointInPolygon from "@turf/boolean-point-in-polygon";

import { LatLonField } from "../graphql/dato/__generated__/dato-graphql.generated";

const getDistanceBetweenCoordinates = (
  location1?: LatLonField | null,
  location2?: LatLonField | null
) => {
  let distance = null;
  if (
    location1?.longitude &&
    location1?.latitude &&
    location2?.longitude &&
    location2?.latitude
  ) {
    const from = turfPoint([location1?.longitude, location1?.latitude]);
    const to = turfPoint([location2?.longitude, location2?.latitude]);
    const options: { units?: TurfUnits } = { units: "meters" };
    distance = turfDistance(from, to, options);
  }

  return distance;
};

// Calculates bearing between two coordinates
const calculateBearing = (start: number[], end: number[]): number => {
  var point1 = turfPoint(start);
  var point2 = turfPoint(end);
  return turfBearing(point1, point2);
};

const determineIsSignificantBearingChange = (
  newBearing: number,
  lastBearing: number,
  bearingTreshold: number
) => {
  const diff = Math.abs(((newBearing - lastBearing + 540) % 360) - 180);
  return diff > bearingTreshold;
};

const lerp = (start: number, end: number, t: number) => {
  return start * (1 - t) + end * t;
};

const normalizeBearing = (bearing: number, currentBearing: number) => {
  // Normalize bearing to 0-360 range
  bearing = bearing % 360;
  if (bearing < 0) bearing += 360;

  const diff = bearing - currentBearing;

  if (diff > 180) bearing -= 360;
  if (diff < -180) bearing += 360;

  return bearing;
};

const getLineDistance = (coordinates: Position[]): number => {
  if (!coordinates.length) return 0;
  const line = turfLineString(coordinates);
  return turfLength(line, { units: "meters" });
};

const getBbox = (
  coordinates: number[][]
): [number, number, number, number] | null => {
  if (!coordinates?.length) return null;

  if (coordinates.length < 4) {
    // If we have less than 4 points, calculate bbox manually
    const lngs = coordinates.map((coord) => coord[0]);
    const lats = coordinates.map((coord) => coord[1]);

    const minLng = min<number>(lngs)!;
    const maxLng = max<number>(lngs)!;
    const minLat = min<number>(lats)!;
    const maxLat = max<number>(lats)!;

    // Add some padding if we only have 1 point
    if (coordinates.length === 1) {
      const padding = 0.002;
      return [
        minLng - padding,
        minLat - padding,
        maxLng + padding,
        maxLat + padding,
      ];
    }

    return [minLng, minLat, maxLng, maxLat];
  }

  // For actual polygons, ensure the polygon is closed (first and last points are the same)
  const polygonCoords = [...coordinates];
  if (
    head(head(polygonCoords)) !== head(last(polygonCoords)) ||
    last(head(polygonCoords)) !== last(last(polygonCoords))
  ) {
    polygonCoords.push([...polygonCoords[0]]); // Add the first point to the end to close the polygon
  }

  const polygon = turfPolygon([polygonCoords]);
  return turfBbox(polygon) as [number, number, number, number];
};

const isPointInPolygon = (
  point: LatLonField | null,
  polygonCoords: number[][]
) => {
  if (!point?.latitude || !point?.longitude || !polygonCoords?.length)
    return false;

  // Create a Turf point and polygon
  const turfPointObj = turfPoint([point.longitude, point.latitude]);
  const turfPolygonObj = turfPolygon([polygonCoords]);

  return turfBooleanPointInPolygon(turfPointObj, turfPolygonObj);
};

export {
  getDistanceBetweenCoordinates,
  calculateBearing,
  determineIsSignificantBearingChange,
  lerp,
  normalizeBearing,
  getLineDistance,
  getBbox,
  isPointInPolygon,
};
