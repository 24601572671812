import { memo } from "react";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonToolbar,
} from "@ionic/react";
import { checkmarkCircle, close, heart, playCircle } from "ionicons/icons";
import { Image } from "react-datocms";
import { useTranslation } from "react-i18next";

import { Story } from "../../interfaces/Interfaces";
import CategoryTags from "../category-tags/CategoryTags";
import ToursRelatedToStories from "../ToursRelatedToStories";
import PlaceSearchImage from "../media/PlaceSearchImage";
import { LatLonField } from "../../graphql/dato/__generated__/dato-graphql.generated";
import { isPremiumStory } from "../../helpers/story-helpers";
import { useGetStoryLikeStatisticsQuery } from "../../graphql/backend/__generated__/backend-graphql-sdk.generated";

const StoryMapStoryDetailModal: React.FC<{
  story: Story;
  isViewed?: boolean;
  navigateToStory: ({ story }: { story: Story }) => void;
  onDismiss: () => void;
}> = ({ story, isViewed, navigateToStory, onDismiss }) => {
  const { t } = useTranslation();
  const { data } = useGetStoryLikeStatisticsQuery({
    variables: {
      input: {
        datoStoryId: story.id,
      },
    },
  });
  const storyLikesAmount =
    data?.storyEvaluation?.getStoryLikeStatistics?.totalLikesAmount;

  const creatorName = story.creatorProfile?.creatorName;
  const creatorProfileImage = (story.creatorProfile as any)?.roundProfileImage
    ?.responsiveImage;

  return (
    <IonPage className="bg-white">
      <IonHeader className="ion-no-border pl-5 pr-2 pt-2">
        <IonToolbar style={{ "--background": "#ffffff" }}>
          <div className="text-[0.875rem] font-medium text-[#687582]">
            {t("storyDetailModal.header.storyDetails")}
          </div>

          {!!story?.displayedPlaceName && (
            <div className="text-[1.25rem] font-bold text-[#232437]">
              {story?.displayedPlaceName}
            </div>
          )}

          <IonButtons slot="end">
            <IonButton
              className="h-[32px] w-[32px] rounded-full bg-[#F4F4F4] text-[#737373]"
              style={{
                "--padding-start": "5px",
                "--padding-end": "5px",
              }}
              onClick={() => onDismiss()}
            >
              <IonIcon icon={close} className="h-[32px] w-[32px]" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <div className="flex flex-col bg-white px-5 pt-2">
          {!!story?.placeName && (
            <IonCard className="mx-0 mb-7 mt-0 flex h-full max-w-[270px] rounded-[12px] shadow-[0px_3px_10px_0px_rgba(0,0,0,0.07)]">
              <div className="relative aspect-[3/2] w-full">
                <PlaceSearchImage
                  searchText={story?.placeName as string}
                  location={story?.location as LatLonField}
                  maxWidth={576}
                  className="absolute"
                />
              </div>
            </IonCard>
          )}

          <div className="mb-2 text-[1.25rem] font-bold text-[#232437]">
            {story.title}
          </div>

          <div className="mb-4 flex items-center justify-between">
            <div>
              <div className="mb-3 flex items-center">
                {creatorProfileImage && (
                  <div className="mr-2 h-[20px] w-[20px] shrink-0">
                    <Image
                      data={creatorProfileImage}
                      className="rounded-full shadow"
                      pictureClassName="rounded-full"
                    />
                  </div>
                )}
                <div className="line-clamp-2 text-[0.8125rem] font-medium text-[#535E69]">
                  {creatorName}
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center gap-4">
                  <div className="flex items-center">
                    <IonIcon
                      icon={heart}
                      slot="end"
                      className="mr-0.5 h-[13px] w-[13px] text-[#5A5A5A]"
                    />
                    <div className="text-[0.8125rem] font-semibold text-[#5A5A5A]">
                      {storyLikesAmount || 0}
                    </div>
                  </div>

                  {isPremiumStory(story) && (
                    <span className="rounded-[5px] bg-[#9768084a] px-2 py-1 text-[0.875rem] font-bold text-[#976808]">
                      {t("storyCard.premium")}
                    </span>
                  )}

                  {!isViewed && (
                    <div className="flex items-center">
                      <IonIcon
                        icon={checkmarkCircle}
                        className="mr-1 h-[20px] w-[20px] text-[#8ED283]"
                      />
                      <div className="text-[0.8125rem] font-medium text-[#8ED283]">
                        {t("storyCard.played")}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <IonIcon
              icon={playCircle}
              className="mr-1 h-[65px] w-[65px] text-[#E16A4F]"
              onClick={() => navigateToStory({ story })}
            />
          </div>

          {/* set min-height because the badges are cut off in ios 17 if use line-clamp-2 */}
          <CategoryTags
            categories={story.categories}
            wrapperClassName="line-clamp-2 leading-[18px] mt-0.5 mb-5 min-h-[54px]"
          />

          <ToursRelatedToStories
            stories={[story]}
            labelTranslationPath="storyDetailModal.relatedTours.label"
          />

          <div
            style={{
              paddingBottom: "calc(var(--safe-area-inset-bottom) + 30px)",
            }}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default memo(StoryMapStoryDetailModal);
