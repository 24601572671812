import { memo } from "react";
import { IonButton, IonContent, IonIcon, IonPage } from "@ionic/react";
import { checkmarkDoneCircle, chevronBackOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";

import { Story } from "../../interfaces/Interfaces";

const CreateStorySuccessModal: React.FC<{
  storyId?: string;
  navigateToStory: ({ story }: { story: Story }) => void;
  onDismiss: () => void;
}> = ({ storyId, navigateToStory, onDismiss }) => {
  const { t } = useTranslation();

  return (
    <IonPage className="min-h-[100vh]">
      <IonContent color="dark" className="ion-padding">
        <div className="min-h-full p-5">
          <div className="flex items-center">
            {/*TODO: add functionality to dismiss modal */}
            <IonButton
              fill="clear"
              color="medium"
              style={{
                "--padding-start": 0,
              }}
              onClick={() => onDismiss()}
            >
              <IonIcon slot="icon-only" icon={chevronBackOutline} />
            </IonButton>
            <h3 className="w-full py-5 pr-10 text-center text-[1.125rem] font-bold text-white">
              {t("createStoryForSightseeingSpot.createStoryForm.title")}
            </h3>
          </div>

          <div className="text-center text-white">
            <p className="pb-5 pt-10 font-bold">
              {t(
                "createStoryForSightseeingSpot.creationProcess.success.messageOne"
              )}
            </p>
            <IonIcon icon={checkmarkDoneCircle} className="mb-5 text-[10rem]" />
            <p className="py-5">
              {t(
                "createStoryForSightseeingSpot.creationProcess.success.messageTwo"
              )}
            </p>
            <IonButton
              className="mx-auto mt-6 block max-w-sm text-[1rem] font-bold normal-case"
              onClick={() => {
                navigateToStory({ story: { id: storyId } } as any);
                onDismiss();
              }}
            >
              {t(
                "createStoryForSightseeingSpot.creationProcess.success.buttons.exploreStory"
              )}
            </IonButton>
          </div>

          <div
            style={{
              paddingBottom: "calc(var(--safe-area-inset-bottom) + 30px)",
            }}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default memo(CreateStorySuccessModal);
