import React from "react";
import ReactDOM from "react-dom";
import { Capacitor } from "@capacitor/core";
import { App as CapacitorApp } from "@capacitor/app";
import { SafeArea } from "@capacitor-community/safe-area";
import * as SentryCapacitor from "@sentry/capacitor";
import * as SentryReact from "@sentry/react";
import { ClickToComponent } from "click-to-react-component";

import AppShell from "./AppShell";
import ErrorFallbackComponent from "./components/ErrorFallbackComponent";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

// Import i18n config and translations
import "./config/i18n.config";

if (Capacitor.isNativePlatform()) {
  SafeArea.enable({
    config: {
      customColorsForSystemBars: true,
      statusBarColor: "#00000000", // transparent
      statusBarContent: "light",
      navigationBarColor: "#00000000", // transparent
      navigationBarContent: "light",
    },
  });
}

ReactDOM.render(
  <React.StrictMode>
    <ClickToComponent />
    <SentryReact.ErrorBoundary showDialog fallback={<ErrorFallbackComponent />}>
      <AppShell />
    </SentryReact.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const sentryBrowserOptions = {
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT_NAME,

  // Browser tracing in Sentry React
  integrations: [SentryReact.browserTracingIntegration()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.1,

  // Ignore certain errors
  ignoreErrors: ["ChunkLoadError"],
};

// Capture errors with Sentry
//  - Capture both native errors and JavaScript errors arising in React
if (Capacitor.isNativePlatform()) {
  // Initialize both Sentry for Capacitor as well as React
  CapacitorApp.getInfo().then(({ version }) => {
    SentryCapacitor.init(
      {
        ...sentryBrowserOptions,
        // Set your release version, such as "getsentry@1.0.0"
        release: `guidable@${version}`,
      },
      SentryReact.init
    );
  });
} else {
  // Initialize Sentry only for React
  SentryReact.init(sentryBrowserOptions);
}
