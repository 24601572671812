import { IonButton, IonContent, IonIcon, useIonRouter } from "@ionic/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { find, toLower } from "lodash-es";
import { useTranslation } from "react-i18next";
import { DeepPartial } from "react-hook-form";
import { qrCodeOutline } from "ionicons/icons";

import AppLayout from "../layouts/AppLayout";
import useAuthStore from "../stores/useAuthStore";
import useRoutes from "../hooks/useRoutes";
import {
  useGetUserQuizTaskByWithParentsLazyQuery,
  UserQuizTask,
  useUpdateUserQuizTaskAndGetParentsMutation,
} from "../graphql/backend/__generated__/backend-graphql-sdk.generated";
import useError from "../hooks/useError";
import useIonVisible from "../hooks/useIonVisible";
import useTour from "../hooks/useTour";

const QrCodeTaskCompletionPage: React.FC = () => {
  const router = useIonRouter();
  const { taskReferenceId }: { taskReferenceId: string } = useParams();
  const { t } = useTranslation();
  const { currentPath, loginPath } = useRoutes();
  const { handleBackendError } = useError();
  const { isVisible } = useIonVisible();
  const { storyPath } = useRoutes();

  const [getUserQuizTaskByQuery] = useGetUserQuizTaskByWithParentsLazyQuery();
  const [updateUserQuizTaskMutation] =
    useUpdateUserQuizTaskAndGetParentsMutation();

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const isUserInitialised = useAuthStore((state) => state.isUserInitialised);

  const [userQuizTask, setUserQuizTask] =
    useState<DeepPartial<UserQuizTask> | null>();
  const [tourId, setTourId] = useState<string | null>(null);
  const [navigationPath, setNavigationPath] = useState<string | null>(null);

  // Fetch story with or without tour data, depending on the available URL query params
  const { tour } = useTour({ tourId, pause: !tourId });

  const getUserQuizTask = async () => {
    await handleBackendError(async () => {
      const { data, error } = await getUserQuizTaskByQuery({
        fetchPolicy: "no-cache",
        variables: {
          input: {
            fieldValue: toLower(taskReferenceId),
            fieldName: "referenceId",
          },
        },
      });

      if (error) return error;

      setUserQuizTask(data?.userQuizTask?.getUserQuizTaskBy);
    });
  };

  const completeUserQuizTask = async () => {
    if (userQuizTask) {
      await handleBackendError(async () => {
        const { errors, data } = await updateUserQuizTaskMutation({
          variables: {
            input: {
              datoQuizTaskId: userQuizTask.datoQuizTaskId as string,
              isCompleted: true,
            },
          },
        });
        if (errors) return errors;

        setUserQuizTask(data?.userQuizTask?.updateUserQuizTask);
      });
    }
  };

  useEffect(
    () => {
      if (isVisible && isUserInitialised) {
        if (!isAuthenticated) {
          router.push(loginPath(currentPath()));
        } else {
          getUserQuizTask();
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAuthenticated, isUserInitialised, isVisible]
  );

  useEffect(
    () => {
      if (userQuizTask?.isStarted && !userQuizTask?.isCompleted) {
        completeUserQuizTask();
      } else if (userQuizTask?.isCompleted) {
        // receive the current tour by setting the tour id
        const tourId =
          userQuizTask?.userQuiz?.userStorySlide?.userStory?.userTourStop
            ?.userTour?.datoTourId;

        if (tourId) {
          setTourId(tourId);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userQuizTask]
  );

  useEffect(
    () => {
      if (userQuizTask && tour) {
        const quizUserStorySlide = userQuizTask?.userQuiz?.userStorySlide;
        const userStory = quizUserStorySlide?.userStory;
        const userTourStop = userStory?.userTourStop;
        const storyId = userStory?.datoStoryId;
        const tourStopId = userTourStop?.datoTourStopId;

        const tourStop = find(tour?.tourStops, ["id", tourStopId]);
        const story = find(tourStop?.stories, ["id", storyId]);

        if (quizUserStorySlide) {
          // navigate to the current story
          if (story) {
            const navigationPath = storyPath({
              story,
              tourStop,
              tour,
            });

            setNavigationPath(navigationPath);
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userQuizTask, tour]
  );

  return (
    <AppLayout>
      <IonContent
        style={{
          "--padding-top": "var(--safe-area-inset-top)",
        }}
      >
        <div className="relative mx-auto flex min-h-full max-w-xl flex-col items-center justify-center bg-[#FAFAFA] p-5">
          <IonIcon
            icon={qrCodeOutline}
            className="mb-10 text-[10rem] text-[#E38873]"
          />

          <p className="px-5 pb-5 text-center text-[1.25rem] font-bold text-[#414254]">
            {userQuizTask?.isCompleted
              ? t("qrCodeTaskCompletionPage.isCompleted")
              : !userQuizTask?.isStarted
              ? t("qrCodeTaskCompletionPage.isNotActivated")
              : ""}
          </p>

          {!!navigationPath && (
            <IonButton
              size="large"
              shape="round"
              className="mt-[20px] w-full max-w-md text-[1rem] font-medium normal-case"
              routerLink={navigationPath}
            >
              {t("qrCodeTaskCompletionPage.buttons.continue")}
            </IonButton>
          )}
        </div>
      </IonContent>
    </AppLayout>
  );
};

export default QrCodeTaskCompletionPage;
