import { IonButton, IonIcon } from "@ionic/react";
import { useTranslation } from "react-i18next";
import {
  GoogleLoginResponse,
  GoogleLoginResponseOnline,
  SocialLogin,
} from "@capgo/capacitor-social-login";
import { Capacitor } from "@capacitor/core";
import { useEffect } from "react";

import {
  SocialProvider,
  useSocialSignInMutation,
} from "../../graphql/backend/__generated__/backend-graphql-sdk.generated";
import useToast from "../../hooks/useToast";
import googleIcon from "../../assets/auth/icons/google.svg";
import useError from "../../hooks/useError";
import { checkReturnedRegistrationData } from "../../helpers/auth-helpers";
import { MixpanelEvents, useMixpanel } from "../../contexts/MixpanelContext";
import { useAuth } from "../../contexts/AuthContext";

const GoogleSingInButton: React.FC<{ source: "login" | "sign-up" }> = ({
  source,
}) => {
  const { presentToast } = useToast();
  const { t } = useTranslation();
  const { setCurrentUserWithRelatedData } = useAuth();
  const { handleBackendError } = useError();
  const { mixpanel, mixpanelEnabled } = useMixpanel();

  const [socialSignInMutation] = useSocialSignInMutation();

  const signInWithGoogle = async () => {
    if (mixpanelEnabled) {
      mixpanel.track(
        source === "login"
          ? MixpanelEvents.LOG_IN_VIA_GOOGLE
          : MixpanelEvents.SIGN_UP_VIA_GOOGLE
      );
    }

    let resourceUrlResponse: {
      provider: "google";
      result: GoogleLoginResponse;
    } | null = null;
    try {
      resourceUrlResponse = await SocialLogin.login<"google">({
        provider: "google",
        options: {
          scopes: ["profile", "email"],
        },
      });
    } catch (e) {
      presentToast("socialAuth.google.error", "danger");
      return;
    }

    const loginResult =
      resourceUrlResponse?.result as GoogleLoginResponseOnline;
    const accessToken = loginResult?.accessToken?.token;
    if (!accessToken) {
      presentToast("socialAuth.google.error", "danger");
      return;
    }

    await handleBackendError(async () => {
      const { data, errors } = await socialSignInMutation({
        variables: {
          input: {
            socialProvider: SocialProvider.Google,
            accessToken,
          },
        },
      });

      if (errors) return errors;

      const me = data?.auth?.socialSignIn;

      if (loginResult?.profile?.email && me) {
        checkReturnedRegistrationData(
          {
            email: loginResult?.profile?.email,
          } as any,
          me
        );
      }

      if (mixpanelEnabled && me) {
        mixpanel.track(
          source === "login"
            ? MixpanelEvents.LOG_IN_SUCCESS
            : MixpanelEvents.SIGN_UP_SUCCESS,
          {
            sentEmail: loginResult?.profile?.email,
            returnedEmail: me.email,
          }
        );
      }

      setCurrentUserWithRelatedData(me);
    });
  };

  useEffect(() => {
    SocialLogin.initialize({
      google:
        Capacitor.getPlatform() === "ios"
          ? {
              iOSClientId: process.env.REACT_APP_GOOGLE_AUTH_IOS_APP_ID,
            }
          : {
              webClientId: process.env.REACT_APP_GOOGLE_AUTH_WEB_APP_ID,
            },
    });
  }, []);

  return (
    <IonButton
      onClick={signInWithGoogle}
      className="text-[1rem] normal-case text-[#344054]"
      color="light"
      size="large"
    >
      <IonIcon icon={googleIcon} slot="start" className="mr-2" />
      {t("socialAuth.google.buttonText")}
    </IonButton>
  );
};

export default GoogleSingInButton;
