import { IonHeader, IonTitle, IonToolbar } from "@ionic/react";
import FloatingBackButton from "../buttons/FloatingBackButton";
import { useTranslation } from "react-i18next";
import { chevronBackOutline } from "ionicons/icons";

const AuthHeader: React.FC<{
  titleTranslationPath: string;
}> = ({ titleTranslationPath }) => {
  const { t } = useTranslation();

  return (
    <IonHeader className="ion-no-border">
      <IonToolbar
        color="primary"
        style={{ paddingTop: "var(--safe-area-inset-top)" }}
      >
        <div className="relative mx-auto max-w-xl">
          <FloatingBackButton
            style={{ marginTop: 0, top: "-10px" }}
            buttonStyle={{ "--box-shadow": "none" }}
            icon={chevronBackOutline}
            color="primary"
          />

          <IonTitle className="mx-10 text-center">
            {t(titleTranslationPath)}
          </IonTitle>
        </div>
      </IonToolbar>
    </IonHeader>
  );
};

export default AuthHeader;
