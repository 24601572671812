import { BarcodeScanner, Barcode, StartScanOptions } from '@capacitor-mlkit/barcode-scanning';
import { useState } from 'react';

const useBarcodeScanner = () => {
  const [isPermissionGranted, setIsPermissionGranted] = useState<boolean>(false);

  const didUserGrantPermission = async () => {
    const status = await BarcodeScanner.checkPermissions();
    if (status.camera === 'granted') return true;

    const statusRequest = await BarcodeScanner.requestPermissions();
    return statusRequest.camera === 'granted';
  };

  const checkPermission = async () => {
    const didGrantPermission = await didUserGrantPermission();
    setIsPermissionGranted(didGrantPermission);
  };

  const startScan = async (options?: StartScanOptions): Promise<Barcode | null> => {
    return new Promise(async (resolve) => {
      if (!isPermissionGranted) resolve(null);

      // make background of WebView transparent
      document.querySelector('body')?.classList.add('barcode-scanner-active');

      // start scanning and wait for a result
      const listener = await BarcodeScanner.addListener(
        "barcodesScanned",
        async (result) => {
          await listener.remove();
          stopScan();
          resolve(result.barcodes[0]);
        }
      );

      await BarcodeScanner.startScan(options);
    });
  };

  const stopScan = async () => {
    // make background of WebView opaque
    document.querySelector('body')?.classList.remove('barcode-scanner-active');
    await BarcodeScanner.stopScan();
  };

  const openAppSettings = () => {
    BarcodeScanner.openSettings();
  };

  return { isPermissionGranted, checkPermission, openAppSettings, startScan, stopScan };
};

export default useBarcodeScanner;
