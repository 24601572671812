import { memo } from "react";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonPage,
  IonToolbar,
} from "@ionic/react";
import { find, flatten, map, uniqBy } from "lodash-es";
import { Image, ResponsiveImageType } from "react-datocms";
import { useTranslation } from "react-i18next";
import { addCircle, close } from "ionicons/icons";

import { Category, SightseeingSpot, Story } from "../../interfaces/Interfaces";
import StoryCardSlider from "../sliders/StoryCardSlider";
import useAuthStore from "../../stores/useAuthStore";
import CategoryTags from "../category-tags/CategoryTags";
import Slider from "../sliders/Slider";
import ToursRelatedToStories from "../ToursRelatedToStories";
import { LatLonField } from "../../graphql/dato/__generated__/dato-graphql.generated";
import PlaceSearchImage from "../media/PlaceSearchImage";

const sightseeingSpotImagesSliderProps = {
  // Enable virtual slides
  virtual: true,

  // Default config (when window width is < 320px)
  slidesPerView: 1,
  spaceBetween: 6,

  // Responsive breakpoints
  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 1.3,
      spaceBetween: 6,
    },
    // when window width is >= 480px
    480: {
      slidesPerView: 1.7,
      spaceBetween: 6,
    },
    // when window width is >= 640px
    640: {
      slidesPerView: 2.4,
      spaceBetween: 6,
    },
  },

  // Round lengths of slides to whole numbers
  // since there's text on most images
  roundLengths: true,
};

const storiesSliderProps = {
  // Enable virtual slides
  virtual: true,

  // Default config (when window width is < 320px)
  slidesPerView: 1.5,
  spaceBetween: 4,

  // Responsive breakpoints
  breakpoints: {
    // when window width is >= 480px
    480: {
      slidesPerView: 2,
      spaceBetween: 6,
    },
  },
};

const StoryMapSightseeingSpotInfo: React.FC<{
  sightSeeingSpot?: SightseeingSpot;
  cluster: Story[];
  navigateToStory: ({ story }: { story: Story }) => void;
  navigateToLoginPage: () => void;
  setIsCreationPage: (isCreationPage: boolean) => void;
  onDismiss: () => void;
}> = ({
  sightSeeingSpot,
  cluster,
  navigateToStory,
  navigateToLoginPage,
  setIsCreationPage,
  onDismiss,
}) => {
  const { t } = useTranslation();

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  const sightSeeingSpotMediaGalleryImages = map(
    sightSeeingSpot?.mediaGallery,
    (mediaGalleryImage) =>
      mediaGalleryImage?.rectangularMediaGalleryImage?.responsiveImage
  );
  const sightSeeingSpotPreviewImages = map(
    sightSeeingSpot?.preview,
    (previewImage) => previewImage?.rectangularPreviewImage?.responsiveImage
  );
  const sightSeeingSpotImages = sightSeeingSpotMediaGalleryImages?.length
    ? sightSeeingSpotMediaGalleryImages
    : sightSeeingSpotPreviewImages;

  const categories = uniqBy(
    flatten(map(cluster, (story) => story.categories)),
    (category) => category?.id
  );

  const storyWithPlaceName = find(
    cluster,
    (story) => !!story?.displayedPlaceName
  );

  return (
    <IonPage className="bg-white">
      <IonHeader className="ion-no-border pl-5 pr-2 pt-2">
        <IonToolbar style={{ "--background": "#ffffff" }}>
          <div className="text-[0.875rem] font-medium text-[#687582]">
            {t("sightseeingSpotInfo.header.spotDetail")}
          </div>
          {(!!sightSeeingSpot?.name || !!storyWithPlaceName) && (
            <div className="text-[1.25rem] font-bold text-[#232437]">
              {sightSeeingSpot?.name || storyWithPlaceName?.displayedPlaceName}
            </div>
          )}
          <IonButtons slot="end">
            <IonButton
              className="h-[32px] w-[32px] rounded-full bg-[#F4F4F4] text-[#737373]"
              style={{
                "--padding-start": "5px",
                "--padding-end": "5px",
              }}
              onClick={() => onDismiss()}
            >
              <IonIcon icon={close} className="h-[32px] w-[32px]" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <div className="bg-white">
          {/* TODO: delete this condition when the GPT functionality is ready */}
          <div className="mb-4 px-5 pt-2">
            {process.env.REACT_APP_DISPLAY_CREATE_STORY_BUTTON === "true" && (
              <IonButton
                shape="round"
                className="mb-7 text-[0.875em] normal-case"
                style={{
                  "--padding-start": "15px",
                  "--padding-end": "15px",
                }}
                onClick={() =>
                  isAuthenticated
                    ? setIsCreationPage(true)
                    : navigateToLoginPage()
                }
              >
                <IonIcon slot="start" className="" icon={addCircle} />
                <span className="font-semibold">
                  {t("sightseeingSpotInfo.buttons.createStory")}
                </span>
              </IonButton>
            )}

            {(!!sightSeeingSpot || !!storyWithPlaceName) && (
              <div>
                <div className="mx-[-4px]">
                  <Slider sliderProps={sightseeingSpotImagesSliderProps}>
                    {(sightSeeingSpotImages?.length
                      ? sightSeeingSpotImages
                      : [storyWithPlaceName]
                    )?.map((imageData: any, i) => (
                      <IonCard
                        key={i}
                        className="mx-1 my-0 flex h-full rounded-[12px] shadow-[0px_3px_10px_0px_rgba(0,0,0,0.07)]"
                      >
                        <div className="relative aspect-[3/2] w-full">
                          {!!sightSeeingSpotImages?.length ? (
                            <Image
                              data={imageData as ResponsiveImageType}
                              layout="fill"
                              objectFit="cover"
                            />
                          ) : (
                            <PlaceSearchImage
                              searchText={
                                (imageData?.placeName ||
                                  sightSeeingSpot?.name) as string
                              }
                              location={
                                (imageData?.location ||
                                  sightSeeingSpot?.location) as LatLonField
                              }
                              maxWidth={576}
                              className="absolute"
                            />
                          )}
                        </div>
                      </IonCard>
                    ))}
                  </Slider>
                </div>

                {!!sightSeeingSpot?.description && (
                  <div className="pt-5 text-[0.9375rem] text-[#2A333A]">
                    {sightSeeingSpot?.description}
                  </div>
                )}
              </div>
            )}

            {!!categories?.length && (
              <div>
                <IonLabel className="block py-4 text-[1rem] font-medium text-[#535E69]">
                  {t("sightseeingSpotInfo.topCategories.label")}
                </IonLabel>
                <CategoryTags
                  categories={categories as Category[]}
                  wrapperClassName="gap-1.5"
                />
              </div>
            )}
          </div>

          <div className="bg-[#F8F8F9] px-5">
            {!!cluster?.length && (
              <div>
                <IonLabel className="block py-4 text-[1rem] font-medium text-[#535E69]">
                  {t("sightseeingSpotInfo.topStories.label")}
                </IonLabel>
                <div className="mx-[-4px] flex items-center justify-center">
                  <StoryCardSlider
                    stories={cluster}
                    isAllStoriesReceived={!!cluster?.length}
                    navigateToStory={navigateToStory}
                    sliderProps={storiesSliderProps}
                  />
                </div>
              </div>
            )}

            <ToursRelatedToStories
              stories={cluster}
              labelTranslationPath="sightseeingSpotInfo.relatedTours.label"
            />

            <div
              style={{
                paddingBottom: "calc(var(--safe-area-inset-bottom) + 30px)",
              }}
            />
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default memo(StoryMapSightseeingSpotInfo);
