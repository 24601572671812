import { IonContent, useIonViewDidEnter } from "@ionic/react";

import { useTranslation } from "react-i18next";

import guidableLogo from "../assets/GU_Logo_RZ-RGB_neg.svg";
import authFlowBackgroundImage from "../assets/auth/auth-flow-background.png";
import useRoutes from "../hooks/useRoutes";
import { MixpanelEvents, useMixpanel } from "../contexts/MixpanelContext";
import AppLayout from "../layouts/AppLayout";
import AppButton from "../components/buttons/AppButton";

const AuthFlowPage: React.FC = () => {
  const { t } = useTranslation();
  const { localePath } = useRoutes();
  const { mixpanel, mixpanelEnabled } = useMixpanel();

  useIonViewDidEnter(() => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.VIEW_SIGN_UP_OR_LOGIN);
    }
  });

  return (
    <AppLayout>
      <IonContent>
        <div
          className="mx-auto flex min-h-full max-w-xl flex-col items-center justify-center bg-cover bg-center bg-no-repeat p-4"
          style={{ backgroundImage: `url(${authFlowBackgroundImage})` }}
        >
          <img
            src={guidableLogo}
            alt="guidable – stories to explore"
            style={{
              paddingTop: "calc(var(--safe-area-inset-top, 12px) + 50px)",
            }}
          />

          <div className="grow"></div>

          <div className="pb-5 text-center text-[1.875rem] font-medium leading-[2rem] text-white">
            {t("authFlow.subtitle")}
            <br />
            #storiestoexplore
          </div>

          <div className="flex flex-col pb-10">
            <AppButton routerLink={localePath("sign-up")} className="w-full">
              {t("authFlow.buttons.createAccount")}
            </AppButton>
            <AppButton
              fill="outline"
              routerLink={localePath("login")}
              className="w-full"
            >
              {t("authFlow.buttons.alreadyHaveAccount")}
            </AppButton>
          </div>
        </div>
      </IonContent>
    </AppLayout>
  );
};

export default AuthFlowPage;
