import { useTranslation } from "react-i18next";
import { IonButton, IonContent, IonIcon } from "@ionic/react";
import { searchOutline } from "ionicons/icons";

import AppLayout from "../../layouts/AppLayout";

const OfflineWall: React.FC = () => {
  const { t } = useTranslation();

  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <AppLayout>
      <IonContent
        style={{
          "--padding-top": "var(--safe-area-inset-top)",
        }}
      >
        <div className="mx-auto flex min-h-full max-w-xl flex-col items-center justify-between bg-[#FAFAFA] p-5">
          <div className="grow" />
          <IonIcon
            icon={searchOutline}
            className="pb-5 text-[10rem] text-[#D8D8D8]"
          />
          <div className="grow" />
          <div className="pb-5 text-center text-[#414254]">
            <h3 className="font-bold">{t("offlineWall.title")}</h3>
            <p className="text-[0.75rem]">{t("offlineWall.text")}</p>
          </div>
          <div className="grow" />
          <IonButton className="normal-case" onClick={reloadPage}>
            {t("offlineWall.buttons.reload")}
          </IonButton>
          <div className="grow" />
        </div>
      </IonContent>
    </AppLayout>
  );
};

export default OfflineWall;
