// import { StatusBar, Style as StatusBarStyle } from "@capacitor/status-bar";
import { setupIonicReact } from "@ionic/react";
import {
  createClient as createGraphQLClient,
  Provider as GraphQLProvider,
  cacheExchange,
  fetchExchange,
} from "urql";
import { requestPolicyExchange } from "@urql/exchange-request-policy";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { hotjar } from "react-hotjar";
import { Capacitor } from "@capacitor/core";
import { StatusBar, Style as StatusBarStyle } from "@capacitor/status-bar";
import { MixpanelProvider } from "./contexts/MixpanelContext";
import { ApolloProvider } from "@apollo/client";

import App from "./App";

// Tailwind CSS
import "./styles/tailwind.scss";
// Global styles
import "./styles/styles.scss";

// Ionic Framework

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
// import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
// import "@ionic/react/css/padding.css";
// import "@ionic/react/css/float-elements.css";
// import "@ionic/react/css/text-alignment.css";
// import "@ionic/react/css/text-transformation.css";
// import "@ionic/react/css/flex-utils.css";
// import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.scss";

// Mapbox Stylesheet
import "mapbox-gl/dist/mapbox-gl.css";

// Shoelace Component Library
import "@shoelace-style/shoelace/dist/themes/light.css";
import { setBasePath } from "@shoelace-style/shoelace/dist/utilities/base-path";
import useBackend from "./hooks/useBackend";

setBasePath(
  "https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.0.0-beta.86/dist/"
);

// Set up Ionic React
setupIonicReact({
  animated: true,
  swipeBackEnabled: false,
  mode: "md",
});

// Status bar
if (Capacitor.isNativePlatform()) {
  StatusBar.setStyle({ style: StatusBarStyle.Light });
}

// Set up Hotjar
const HOTJAR_SITE_ID = Number(process.env.REACT_APP_HOTJAR_SITE_ID);
if (HOTJAR_SITE_ID) {
  const hotjarScriptVersionNumber = 6;
  hotjar.initialize(HOTJAR_SITE_ID, hotjarScriptVersionNumber);
}

//
// DatoCMS client
//
const DATO_CMS_CONTENT_DELIVERY_API_READ_TOKEN =
  process.env.REACT_APP_DATO_CMS_CONTENT_DELIVERY_API_READ_TOKEN!;

const datoContentDeliveryApiBaseUrl = "https://graphql.datocms.com";
const useDatoPreviewEndpoint =
  process.env.REACT_APP_DATO_CMS_CONTENT_DELIVERY_API_USE_PREVIEW_ENDPOINT ===
  "true";
const datoSandboxEnvironmentName =
  process.env.REACT_APP_DATO_CMS_SANDBOX_ENVIRONMENT_NAME;

const datoEndpointUrlParts = [datoContentDeliveryApiBaseUrl];

if (useDatoPreviewEndpoint && datoSandboxEnvironmentName) {
  datoEndpointUrlParts.push(`environments/${datoSandboxEnvironmentName}`);
}

if (useDatoPreviewEndpoint) {
  datoEndpointUrlParts.push("preview");
}

const datoEndpointUrl = datoEndpointUrlParts.join("/");

const datoClient = createGraphQLClient({
  url: datoEndpointUrl,
  exchanges: [
    requestPolicyExchange({
      ttl: 24 * 60 * 60 * 1000, // 1 day
    }),
    cacheExchange,
    fetchExchange,
  ],
  fetchOptions: {
    headers: {
      Authorization: DATO_CMS_CONTENT_DELIVERY_API_READ_TOKEN,
    },
  },
});

const queryClient = new QueryClient();

// Status bar style in Capacitor
// window
//   .matchMedia("(prefers-color-scheme: dark)")
//   .addListener(async (status) => {
//     try {
//       await StatusBar.setStyle({
//         style: status.matches ? StatusBarStyle.Dark : StatusBarStyle.Light,
//       });
//     } catch {}
//   });

const AppShell: React.FC = () => {
  const { apolloClient } = useBackend();

  if (!apolloClient) {
    return null;
  }

  return (
    <MixpanelProvider
      config={{
        debug: process.env.NODE_ENV === "development",
        // Route data to Mixpanel's EU servers
        api_host: "https://api-eu.mixpanel.com",
      }}
    >
      <GraphQLProvider value={datoClient}>
        <ApolloProvider client={apolloClient}>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools />
            <App />
          </QueryClientProvider>
        </ApolloProvider>
      </GraphQLProvider>
    </MixpanelProvider>
  );
};

export default AppShell;
