import { useEffect } from "react";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { Capacitor } from '@capacitor/core';
import { useIonRouter } from "@ionic/react";
// TODO: Uncomment when branch.io plugin is updated
// import { BranchDeepLinks, BranchInitEvent } from 'capacitor-branch-deep-links';
import { some, startsWith } from 'lodash-es';

import { UNIVERSAL_LINKS_DOMAINS } from '../constants';

const AppUrlListener: React.FC = () => {
  const router = useIonRouter();

  const getSlugAndNavigate = (url: string) => {
    const slug = url?.split(/guidable\.(com|de)/g)?.pop();

    if (slug) {
      router.push(slug);
    } else {
      // If no match, do nothing - let regular routing logic take over
    }
  };

  // Handle URL open events
  // Source: https://capacitorjs.com/docs/guides/deep-links#react
  useEffect(
    () => {
      // handle universal links
      App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
        // Example for handled URLs:
        // - https://app.guidable.com/de/stories => slug === "/de/stories"
        // - https://app.guidable.de/de/stories => slug === "/de/stories"
        // - https://app.guidable.com/de/story?storyId=1234567890 => slug === "/de/story?storyId=1234567890"
        // - https://app.guidable.de/de/story?storyId=1234567890 => slug === "/de/story?storyId=1234567890"

        if (some(UNIVERSAL_LINKS_DOMAINS, (domain) => startsWith(event?.url, domain))) {
          getSlugAndNavigate(event?.url);
        }
      });

      if (Capacitor.isNativePlatform()) {
        // handle smart banner links
        // BranchDeepLinks.addListener('init', (event: BranchInitEvent) => {
        //   const referringParams = event?.referringParams;
        //   if (referringParams?.['+clicked_branch_link']) {
        //     getSlugAndNavigate(referringParams?.$canonical_url);
        //   }
        // });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return null;
};

export default AppUrlListener;
