import { useCallback, useEffect, useState } from "react";
import { IonCard, IonContent } from "@ionic/react";
import { useParams } from "react-router";
import { find, map } from "lodash-es";
import { Image, ResponsiveImageType } from "react-datocms";

import FloatingBackButton from "../components/buttons/FloatingBackButton";
import AppLayout from "../layouts/AppLayout";
import { useLocale } from "../contexts/LocaleContext";
import { Tour, TourCollection } from "../interfaces/Interfaces";
import {
  useTourCollectionByIdQuery,
  useToursByTourCollectionIdQuery,
} from "../graphql/dato/__generated__/dato-graphql.generated";
import { useGetToursRatingStatisticsQuery } from "../graphql/backend/__generated__/backend-graphql-sdk.generated";
import useAuthStore from "../stores/useAuthStore";
import useIonVisible from "../hooks/useIonVisible";
import FullWidthTourCard from "../components/cards/FullWidthTourCard";
import useUserToursAudioStatistics from "../hooks/useUserToursAudioStatistics";

const TourCollectionPage: React.FC = () => {
  const { tourCollectionId }: { tourCollectionId?: string | null } =
    useParams();
  const { queryLocale } = useLocale();
  const { isVisible } = useIonVisible();

  const [tourCollection, setTourCollection] = useState<TourCollection | null>(
    null
  );
  const [tours, setTours] = useState<Tour[]>([]);

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  const { userToursAudioStatistics } = useUserToursAudioStatistics(
    map(tours, ({ id }) => id),
    isAuthenticated,
    isVisible
  );

  const [result] = useTourCollectionByIdQuery({
    variables: {
      tourCollectionId,
      locale: queryLocale,
    },
  });
  const { data } = result;

  const { data: toursRatingStatisticsData } =
    useGetToursRatingStatisticsQuery();
  const toursRatingStatistics =
    toursRatingStatisticsData?.tourReview?.getToursRatingStatistics;

  const queryVariables = useCallback(() => {
    return {
      locale: queryLocale,
      tourCollectionId: tourCollection?.id,
    };
  }, [queryLocale, tourCollection?.id]);

  const [toursResult] = useToursByTourCollectionIdQuery({
    variables: queryVariables(),
    pause: !tourCollection?.id,
  });
  const { data: toursData } = toursResult;

  useEffect(() => {
    if (data?.tourCollection)
      setTourCollection(data.tourCollection as TourCollection);
  }, [data]);

  useEffect(
    () => {
      if (toursData?.tours) setTours(toursData?.tours as Tour[]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [toursData]
  );

  return (
    <AppLayout>
      <IonContent
        style={{
          "--padding-top": "var(--safe-area-inset-top)",
        }}
      >
        <div className="relative mx-auto h-full max-w-xl bg-[#FAFAFA]">
          <FloatingBackButton style={{ left: "6px" }} />

          <IonCard className="m-0 grid grid-cols-2 rounded-[0px]">
            <div className="relative aspect-square flex-1">
              {tourCollection?.mediaGallery?.[0]?.image?.responsiveImage && (
                <Image
                  data={
                    tourCollection?.mediaGallery?.[0]?.image
                      ?.responsiveImage as ResponsiveImageType
                  }
                  layout="fill"
                  objectFit="cover"
                />
              )}
            </div>

            <div className="flex items-end bg-[#414254] px-5 pb-10">
              <p className="max-w-[175px] text-[1.5rem] font-bold text-white">
                {tourCollection?.title}
              </p>
            </div>
          </IonCard>

          <div>
            <p className="px-5 pb-2 pt-5 text-[0.75rem] text-[#414254]">
              {tourCollection?.longDescription}
            </p>

            {map(tours, (tour) => {
              const tourRatingStatistics = find(toursRatingStatistics, [
                "datoTourId",
                tour.id,
              ]);
              const userTourAudioStatistics = find(userToursAudioStatistics, [
                "datoTourId",
                tour.id,
              ]);
              return (
                <FullWidthTourCard
                  key={`tour-card-${tour.id}`}
                  tour={tour}
                  tourRatingStatistics={tourRatingStatistics}
                  userTourAudioStatistics={userTourAudioStatistics}
                />
              );
            })}
          </div>
        </div>
      </IonContent>
    </AppLayout>
  );
};

export default TourCollectionPage;
