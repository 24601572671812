import { IonButton, IonContent, useIonRouter } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router";

import AppLayout from "../layouts/AppLayout";
import { useLocale } from "../contexts/LocaleContext";
import useRoutes from "../hooks/useRoutes";
import { useDeleteMeMutation } from "../graphql/backend/__generated__/backend-graphql-sdk.generated";
import FloatingBackButton from "../components/buttons/FloatingBackButton";
import useError from "../hooks/useError";

const DeleteAccountPage: React.FC = () => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const { locale } = useLocale();
  const { localePath } = useRoutes();
  const { handleBackendError } = useError();

  const [deleteMeMutation] = useDeleteMeMutation();

  const deleteMe = async () => {
    await handleBackendError(async () => {
      const { data, errors } = await deleteMeMutation();

      if (errors) return errors;

      const deleteMe = data?.user?.deleteMe;
      if (deleteMe) {
        router.push(
          generatePath(
            "/:locale(de|en|fr|es)/user-profile/delete-account/confirmation",
            { locale }
          )
        );
      }
    });
  };

  return (
    <AppLayout>
      <IonContent
        style={{
          "--padding-top": "var(--safe-area-inset-top)",
        }}
      >
        <div className="relative mx-auto min-h-full max-w-xl bg-[#EAEBF0] p-5 pt-[55px] text-center">
          <FloatingBackButton style={{ left: "6px" }} />

          <h3 className="pb-5 pt-20 text-[1.25rem] font-bold text-[#414254]">
            {t("deleteAccount.pageTitle")}
          </h3>
          <p className="pb-20 text-[0.875rem] text-[#414254]">
            {t("deleteAccount.message")}
          </p>

          <div className="flex flex-col pb-10">
            <IonButton
              fill="outline"
              className="font-normal normal-case"
              style={{ "--background": "#ffffff" }}
              onClick={deleteMe}
            >
              {t("deleteAccount.buttons.deleteAccount")}
            </IonButton>
            <IonButton
              size="large"
              className="text-[1rem] font-normal normal-case"
              routerLink={localePath("user-profile")}
            >
              {t("deleteAccount.buttons.keepAccount")}
            </IonButton>
          </div>
        </div>
      </IonContent>
    </AppLayout>
  );
};

export default DeleteAccountPage;
