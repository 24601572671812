import { useEffect } from "react";
import { IonButton, IonIcon } from "@ionic/react";
import { useTranslation } from "react-i18next";
import {
  FacebookLoginResponse,
  SocialLogin,
} from "@capgo/capacitor-social-login";
import { Capacitor } from "@capacitor/core";

import {
  SocialProvider,
  useSocialSignInMutation,
} from "../../graphql/backend/__generated__/backend-graphql-sdk.generated";
import facebookIcon from "../../assets/auth/icons/facebook.svg";
import useError from "../../hooks/useError";
import { useAuth } from "../../contexts/AuthContext";
import { MixpanelEvents, useMixpanel } from "../../contexts/MixpanelContext";
import useToast from "../../hooks/useToast";
import { checkReturnedRegistrationData } from "../../helpers/auth-helpers";

const FacebookSingInButton: React.FC<{ source: "login" | "sign-up" }> = ({
  source,
}) => {
  const { t } = useTranslation();
  const { setCurrentUserWithRelatedData } = useAuth();
  const { handleBackendError } = useError();
  const { mixpanel, mixpanelEnabled } = useMixpanel();
  const { presentToast } = useToast();

  const [socialSignInMutation] = useSocialSignInMutation();

  const signInWithFacebook = async () => {
    if (mixpanelEnabled) {
      mixpanel.track(
        source === "login"
          ? MixpanelEvents.LOG_IN_VIA_FACEBOOK
          : MixpanelEvents.SIGN_UP_VIA_FACEBOOK
      );
    }

    let authResponse: {
      provider: "facebook";
      result: FacebookLoginResponse;
    } | null = null;
    try {
      authResponse = await SocialLogin.login<"facebook">({
        provider: "facebook",
        options: {
          permissions: ["email", "public_profile"],
          limitedLogin: Capacitor.getPlatform() === "ios",
        },
      });
    } catch (error) {
      presentToast("socialAuth.facebook.error", "danger");
      return;
    }

    const accessToken =
      authResponse?.result?.idToken || authResponse?.result?.accessToken?.token;
    if (!accessToken) {
      presentToast("socialAuth.facebook.error", "danger");
      return;
    }

    await handleBackendError(async () => {
      const { data, errors } = await socialSignInMutation({
        variables: {
          input: {
            socialProvider: SocialProvider.Facebook,
            accessToken,
          },
        },
      });

      if (errors) return errors;

      const me = data?.auth?.socialSignIn;

      if (authResponse?.result?.profile?.email && me) {
        checkReturnedRegistrationData(
          {
            email: authResponse?.result?.profile?.email,
          } as any,
          me
        );
      }

      if (mixpanelEnabled && me) {
        mixpanel.track(
          source === "login"
            ? MixpanelEvents.LOG_IN_SUCCESS
            : MixpanelEvents.SIGN_UP_SUCCESS,
          {
            sentEmail: authResponse?.result?.profile?.email,
            returnedEmail: me.email,
          }
        );
      }

      setCurrentUserWithRelatedData(me);
    });
  };

  useEffect(() => {
    SocialLogin.initialize({
      facebook: {
        appId: process.env.REACT_APP_FACEBOOK_AUTH_APP_ID!,
        clientToken: process.env.REACT_APP_FACEBOOK_AUTH_CLIENT_TOKEN!,
      },
    });
  }, []);

  return (
    <IonButton
      onClick={signInWithFacebook}
      className="text-[1rem] normal-case text-white"
      size="large"
      style={{
        "--background": "#1877F2",
      }}
    >
      <IonIcon icon={facebookIcon} slot="start" className="mr-2" />
      {t("socialAuth.facebook.buttonText")}
    </IonButton>
  );
};

export default FacebookSingInButton;
