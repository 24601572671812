import { useEffect, useRef, useState } from "react";
import Map, {
  MapRef,
  ViewState,
  Marker,
  Layer,
  Source,
  GeoJSONSource,
  MapTouchEvent,
  MapMouseEvent,
  LngLatBounds,
} from "react-map-gl";
import { IonIcon } from "@ionic/react";
import { IonButton } from "@ionic/react";
import {
  close as closeIcon,
  pencil as pencilIcon,
  sync as syncIcon,
  trash as trashIcon,
  checkmarkSharp as checkmarkSharpIcon,
  stopSharp as stopSharpIcon,
} from "ionicons/icons";
import { UseFormSetValue, UseFormWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import type { Position } from "geojson";
import {
  lineString as turfLineString,
  point as turfPoint,
} from "@turf/helpers";
import turfKinks from "@turf/kinks";
import turfLength from "@turf/length";
import turfDistance from "@turf/distance";
import turfAlong from "@turf/along";
import turfLineSliceAlong from "@turf/line-slice-along";
import { ceil, cloneDeep, filter, isEqual, last } from "lodash-es";
import { useThrottledState } from "@react-hookz/web";

import { setMapLanguage } from "../../helpers/map-helpers";
import { useLocale } from "../../contexts/LocaleContext";
import guidableLogo from "../../assets/GU_Logo_RZ-RGB_Icon.svg";
import AppButton from "../../components/buttons/AppButton";
import { City } from "../../interfaces/Interfaces";
import { getBbox } from "../../helpers/turf-helpers";
import useToast from "../../hooks/useToast";
import SightseeingSpotMarkersWithSearchArea from "./SightseeingSpotMarkersWithSearchArea";

const DrawingPolygonModal: React.FC<{
  currentCity: City;
  polygonFormFieldName: string;
  startPointFormFieldName: string;
  endPointFormFieldName: string;
  setFormValue: UseFormSetValue<any>;
  watch: UseFormWatch<any>;
  onDismiss: () => void;
}> = ({
  currentCity,
  polygonFormFieldName,
  startPointFormFieldName,
  endPointFormFieldName,
  setFormValue,
  watch,
  onDismiss,
}) => {
  const mapRef = useRef<MapRef>(null);
  const { locale } = useLocale();
  const { t } = useTranslation();
  const { presentToast } = useToast();

  const mountedRef = useRef(true);

  const [isMapReady, setIsMapReady] = useState(false);
  const [isDrawingMode, setIsDrawingMode] = useState(false);
  const [isActivelyDrawing, setIsActivelyDrawing] = useState(false);

  const drawingPolygonCoordinatesRef = useRef<Position[]>([]);

  const [viewState, setViewState] = useState<ViewState>({
    latitude: currentCity?.location?.latitude || 52.5142,
    longitude: currentCity?.location?.longitude || 13.39,
    zoom: 13,
    bearing: 0,
    pitch: 0,
    padding: { top: 100, bottom: 150, left: 50, right: 50 },
  });
  const [mapBounds, setMapBounds] = useThrottledState<
    LngLatBounds | null | undefined
  >(null, 1000);

  const polygon = watch(polygonFormFieldName);
  const startPoint = watch(startPointFormFieldName);
  const endPoint = watch(endPointFormFieldName);
  // Ref to store previous values for comparison
  const prevCoordinatesRef = useRef({
    polygon: null,
    startPoint: null,
    endPoint: null,
  });

  // Effect for coordinate changes
  useEffect(() => {
    if (!isMapReady) return;

    // Check if values have actually changed
    if (
      isEqual(prevCoordinatesRef.current.polygon, polygon) &&
      isEqual(prevCoordinatesRef.current.startPoint, startPoint) &&
      isEqual(prevCoordinatesRef.current.endPoint, endPoint)
    ) {
      return;
    }

    // Update ref with current values
    prevCoordinatesRef.current = {
      polygon,
      startPoint,
      endPoint,
    };

    const coordinates = [
      ...(polygon?.length ? polygon : []),
      ...(startPoint?.location
        ? [[startPoint.location.longitude, startPoint.location.latitude]]
        : []),
      ...(endPoint?.location
        ? [[endPoint.location.longitude, endPoint.location.latitude]]
        : []),
    ];

    if (coordinates.length) {
      const bbox = getBbox(coordinates)!;
      mapRef.current?.fitBounds(bbox, {
        padding: { top: 100, bottom: 150, left: 50, right: 50 },
      });
    }
  }, [polygon, startPoint, endPoint, isMapReady]);

  const enableDrawingMode = () => {
    deletePolygon();
    setIsDrawingMode(true);
  };

  const startDrawing = (e: MapMouseEvent | MapTouchEvent) => {
    if (isDrawingMode) {
      e.preventDefault();
      setIsActivelyDrawing(true);
    }
  };

  const stopDrawing = () => {
    setIsDrawingMode(false);
    setIsActivelyDrawing(true);
  };

  const handleDrawing = (e: MapTouchEvent | MapMouseEvent) => {
    if (isDrawingMode && isActivelyDrawing && mapRef.current) {
      e.preventDefault();
      // Add the new point to the line
      const animatedLineSource = mapRef.current.getSource(
        "animated-line"
      ) as GeoJSONSource;

      if (animatedLineSource) {
        drawingPolygonCoordinatesRef.current.push([e.lngLat.lng, e.lngLat.lat]);
        animatedLineSource.setData({
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: drawingPolygonCoordinatesRef.current,
          },
        });
      }
    }
  };

  const endDrawing = () => {
    setIsActivelyDrawing(false);
    setIsDrawingMode(false);

    // check if there are at least 3 points to be able to create the polygon
    if (drawingPolygonCoordinatesRef.current?.length < 3) {
      drawingPolygonCoordinatesRef.current = [];
      return;
    }

    let coordinates = cloneDeep(drawingPolygonCoordinatesRef.current);
    // filter coordinates
    coordinates = filter(
      coordinates,
      (_, index) => index % ceil(coordinates?.length / 100) === 0
    );
    // Close the polygon by adding the first point again.
    coordinates.push(coordinates[0]);

    // Create a LineString from the coordinates to check for self-intersections
    const lineString = turfLineString(coordinates);
    const selfIntersects = turfKinks(lineString);

    if (!selfIntersects.features.length) {
      setFormValue(polygonFormFieldName, coordinates);
      return;
    }

    if (selfIntersects.features.length > 1) {
      presentToast(
        "createTour.form.polygon.errors.moreThanOneIntersection",
        "danger"
      );
      deletePolygon();
      return;
    }

    // Get the last intersection point
    const intersectionPoint =
      last(selfIntersects.features)?.geometry?.coordinates || [];

    // Find all points along the line at small intervals
    const lineLength = turfLength(lineString, { units: "meters" });
    const step = lineLength / 100;
    let firstOccurrenceDistance = -1;
    let secondOccurrenceDistance = -1;

    for (let distance = 0; distance <= lineLength; distance += step) {
      const point = turfAlong(lineString, distance, { units: "meters" });

      // Check if this point is very close to the intersection point
      const distanceToIntersection = turfDistance(
        point,
        turfPoint(intersectionPoint),
        { units: "meters" }
      );

      if (distanceToIntersection < step) {
        // Within 1 meter
        if (firstOccurrenceDistance === -1) {
          firstOccurrenceDistance = distance;
        } else if (
          secondOccurrenceDistance === -1 &&
          distance - firstOccurrenceDistance > step * 2
        ) {
          // Ensure it's not the same occurrence
          secondOccurrenceDistance = distance;
          break;
        }
      }
    }

    coordinates =
      turfLineSliceAlong(
        lineString,
        firstOccurrenceDistance,
        secondOccurrenceDistance,
        {
          units: "meters",
        }
      )?.geometry?.coordinates || [];

    // Close the polygon by adding the first point again
    coordinates.push(coordinates[0]);

    setFormValue(polygonFormFieldName, coordinates);
  };

  const deletePolygon = () => {
    drawingPolygonCoordinatesRef.current = [];
    setFormValue(polygonFormFieldName, null);
  };

  const calculateMapBounds = () => {
    const currentMapBounds = mapRef?.current?.getBounds();
    setMapBounds(currentMapBounds);
  };

  useEffect(
    () => {
      return () => {
        mountedRef.current = false;
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className="relative h-full w-full">
      {!isMapReady && (
        <div className="absolute bottom-0 left-0 right-0 top-0 z-[51] flex items-center justify-center bg-white">
          <img
            src={guidableLogo}
            alt="guidable – stories to explore"
            className="w-[150px]"
          />
        </div>
      )}
      <Map
        ref={mapRef}
        {...viewState}
        onMove={(evt) => {
          setViewState(evt.viewState);
          calculateMapBounds();
        }}
        onTouchStart={startDrawing}
        onMouseDown={startDrawing}
        onTouchMove={handleDrawing}
        onMouseMove={handleDrawing}
        onTouchEnd={() => endDrawing()}
        onMouseUp={() => endDrawing()}
        attributionControl={false}
        dragRotate={!isDrawingMode}
        dragPan={!isDrawingMode}
        mapStyle="mapbox://styles/mapbox/streets-v12"
        onLoad={(e) => {
          e.target?.resize();
          setMapLanguage(e, locale);
          setIsMapReady(true);
          calculateMapBounds();
        }}
      >
        <div
          className="relative"
          style={{
            marginTop: "var(--safe-area-inset-top)",
          }}
        >
          <div className="absolute left-1/2 top-8 z-50 -translate-x-1/2 rounded-[30px] bg-white px-9 py-3 text-center text-[1rem] font-light">
            {t("createTour.form.polygon.note")}
          </div>

          <IonButton
            shape="round"
            className="absolute right-2.5 top-2.5 z-50"
            onClick={onDismiss}
          >
            <IonIcon slot="icon-only" icon={closeIcon} />
          </IonButton>
        </div>

        <SightseeingSpotMarkersWithSearchArea
          viewState={viewState}
          mapBounds={mapBounds}
          queryRadius={10000}
        />

        <div className="absolute bottom-20 left-0 right-0 z-50 flex items-center justify-center gap-1">
          {!isDrawingMode ? (
            <AppButton
              onClick={enableDrawingMode}
              shape="round"
              className="w-auto"
            >
              <IonIcon
                icon={polygon?.length ? syncIcon : pencilIcon}
                slot="start"
              ></IonIcon>
              {polygon?.length
                ? t("createTour.form.buttons.startDrawingAgain")
                : t("createTour.form.buttons.startDrawing")}
            </AppButton>
          ) : (
            <AppButton onClick={stopDrawing} shape="round" className="w-auto">
              <IonIcon icon={stopSharpIcon} slot="start"></IonIcon>
              {t("createTour.form.buttons.stopDrawing")}
            </AppButton>
          )}

          {polygon?.length && (
            <>
              <AppButton
                onClick={deletePolygon}
                shape="round"
                color="danger"
                className="w-auto"
              >
                <IonIcon icon={trashIcon} slot="icon-only"></IonIcon>
              </AppButton>
              <AppButton
                onClick={onDismiss}
                shape="round"
                color="success"
                className="w-auto"
              >
                <IonIcon icon={checkmarkSharpIcon} slot="icon-only"></IonIcon>
              </AppButton>
            </>
          )}
        </div>

        {startPoint?.location && (
          <Marker
            longitude={startPoint.location.longitude}
            latitude={startPoint.location.latitude}
            anchor="bottom"
          >
            <div className="flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-green-500 font-bold text-white shadow-lg">
              S
            </div>
          </Marker>
        )}

        {endPoint?.location && (
          <Marker
            longitude={endPoint.location.longitude}
            latitude={endPoint.location.latitude}
            anchor="bottom"
          >
            <div className="flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-red-500 font-bold text-white shadow-lg">
              E
            </div>
          </Marker>
        )}

        {isDrawingMode && (
          <Source
            id="animated-line"
            type="geojson"
            data={{
              type: "Feature",
              properties: {},
              geometry: {
                type: "LineString",
                coordinates: [],
              },
            }}
          >
            <Layer
              type="line"
              layout={{
                "line-cap": "butt",
              }}
              paint={{
                "line-color": "#ef6c4e",
                "line-width": 4,
              }}
            />
          </Source>
        )}

        {!isDrawingMode && polygon?.length && (
          <Source
            id="drawn-polygon"
            type="geojson"
            data={{
              type: "Feature",
              properties: {},
              geometry: {
                type: "Polygon",
                coordinates: [polygon],
              },
            }}
          >
            <Layer
              id="polygon-fill"
              type="fill"
              paint={{
                "fill-color": "#ef6c4e",
                "fill-opacity": 0.3,
              }}
            />
            <Layer
              id="polygon-outline"
              type="line"
              paint={{
                "line-color": "#ef6c4e",
                "line-width": 4,
              }}
            />
          </Source>
        )}
      </Map>
    </div>
  );
};

export default DrawingPolygonModal;
