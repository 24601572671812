import { IonContent, useIonViewDidEnter } from "@ionic/react";
import { map, reduce, sortBy } from "lodash-es";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import AppLayout from "../layouts/AppLayout";
import FloatingBackButton from "../components/buttons/FloatingBackButton";
import StoryCard from "../components/cards/StoryCard";
import useStoryPlayer from "../hooks/useStoryPlayer";
import useError from "../hooks/useError";
import {
  useGetStoriesLikeStatisticsLazyQuery,
  useGetUserStoriesCreatedByMeLazyQuery,
} from "../graphql/backend/__generated__/backend-graphql-sdk.generated";
import { useStoriesByStoryIdsQuery } from "../graphql/dato/__generated__/dato-graphql.generated";
import { useLocale } from "../contexts/LocaleContext";
import { Story } from "../interfaces/Interfaces";

const CreatedStoriesPage: React.FC = () => {
  const { navigateToStory } = useStoryPlayer();
  const { handleBackendError } = useError();
  const { queryLocale } = useLocale();
  const { t } = useTranslation();

  const [getUserStoriesCreatedByMeQuery] =
    useGetUserStoriesCreatedByMeLazyQuery();
  const [getStoriesLikeStatisticsQuery] =
    useGetStoriesLikeStatisticsLazyQuery();

  const [storyIds, setStoryIds] = useState<string[]>([]);
  const [stories, setStories] = useState<Story[]>([]);
  const [sortedStories, setSortedStories] = useState<Story[]>([]);
  const [storiesLikesStatistics, setStoriesLikesStatistics] = useState<{
    [key: string]: number;
  }>({});

  const queryVariables = useCallback(() => {
    return {
      locale: queryLocale,
      storyIds,
      first: 100,
      skip: 0,
    };
  }, [storyIds, queryLocale]);

  const [storiesResult] = useStoriesByStoryIdsQuery({
    variables: queryVariables(),
    pause: !storyIds?.length,
  });

  useIonViewDidEnter(() => {
    const getStatistics = async () => {
      await handleBackendError(async () => {
        const { data, error } = await getUserStoriesCreatedByMeQuery({
          fetchPolicy: "no-cache",
        });

        if (error) return error;

        const storyIds = map(
          data?.userStory?.getUserStoriesCreatedByMe,
          (startedUserStory) => startedUserStory.datoStoryId
        );

        setStoryIds(storyIds);
      });

      await handleBackendError(async () => {
        const { data, error } = await getStoriesLikeStatisticsQuery({
          fetchPolicy: "no-cache",
        });

        if (error) return error;

        const storiesLikesStatisticsData = reduce(
          data?.storyEvaluation?.getStoriesLikeStatistics,
          (acc, storyLikeStatistics) => ({
            ...acc,
            [storyLikeStatistics.datoStoryId]:
              storyLikeStatistics.totalLikesAmount,
          }),
          {}
        );

        setStoriesLikesStatistics(storiesLikesStatisticsData);
      });
    };

    getStatistics();
  });

  useEffect(() => {
    const stories = storiesResult?.data?.stories as Story[];
    setStories(stories);
  }, [storiesResult]);

  useEffect(
    () => {
      // sort stories by amount of likes
      const sortedStories = sortBy(stories, (story) => {
        const totalLikesAmount = storiesLikesStatistics[story.id] || 0;
        return -totalLikesAmount;
      });

      setSortedStories(sortedStories);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stories, storiesLikesStatistics]
  );

  return (
    <AppLayout>
      <IonContent
        style={{
          "--padding-top": "var(--safe-area-inset-top)",
        }}
      >
        <div className="relative mx-auto max-w-md">
          <FloatingBackButton style={{ left: "6px" }} />

          <h3 className="ml-1 pb-2 pl-3 pt-[75px] text-[1.1rem] font-semibold text-[#414254]">
            {t(
              stories?.length
                ? "profile.createdStories.title"
                : "profile.createdStories.doNotHaveCreatedStories"
            )}
          </h3>

          <div className="mx-auto max-w-md p-5">
            {map(sortedStories, (story) => (
              <div key={story.id} className="mb-3">
                <StoryCard
                  story={story}
                  storyLikesAmount={storiesLikesStatistics[story.id] || 0}
                  navigateToStory={navigateToStory}
                />
              </div>
            ))}
          </div>
        </div>
      </IonContent>
    </AppLayout>
  );
};

export default CreatedStoriesPage;
